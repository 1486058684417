@import '../../utils/variables'

.input
  background: white
  border: 1px solid black
  padding: .5rem
  //transition: all .15s
  &:focus, &:hover
    border: 1px solid $dark-color
    //transition: all .15s

.slider-input
  text-align: center