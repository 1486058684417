@import '../../../utils/variables'

.node-info
  background: $dark-color
  color: $light-color
  padding: .5rem
  border-radius: 10px
  text-align: center

.comparison-modal-content
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

.comparison-group
  display: flex
  gap: .5rem

.piece-info-centered
  display: flex
  justify-content: center
  align-items: center
  gap: .2rem