@import '../../../utils/variables'

.error-modal
  position: static
  //left: 50%
  //top: 50%
  //transform: translate(-50%, -50%)
  height: 100%
  width: 100%


.error-modal-content
  text-align: center

.error-data
  font-weight: bold
  font-size: 1.3rem
  color: $dark-color

.error-generic
  font-weight: 300

.error-modal-header
  display: flex
  gap: .4rem
  justify-content: center
  align-items: center
  padding: 2rem