.piece-info {
  display: flex;
  gap: 0.1rem;
}

.info-modal {
  bottom: 10px;
  top: unset;
  left: 50%;
  transform: translateX(-50%);
  right: unset;
  height: 23dvh;
}
.info-modal .modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.info-modal .works-modal-content {
  text-align: center;
}

.info-modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.alpha-beta-info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

