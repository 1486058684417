.transition {
  position: fixed;
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100dvw;
}

.transition-info {
  bottom: 10px;
}

.transition-error {
  top: 10px;
}

