.modal {
  background: whitesmoke;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  position: fixed;
  top: 10px;
  right: 10px;
  max-width: 30vw;
  height: calc(100vh - 20px);
  overflow: auto;
  z-index: 6;
  border-radius: 10px;
}

.modal-header {
  background: #0B3954;
  color: #BFD7EA;
  margin: 0;
  padding: 1rem;
  text-align: center;
}
.modal-header h2 {
  margin: 0;
  padding: 0;
}

.modal-content {
  padding: 1rem;
}

@media screen and (max-width: 1100px) {
  .modal {
    max-width: 90vw;
    width: 100%;
    height: 20dvh;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
  }
}

