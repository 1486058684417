@import '../../utils/variables'

.navigation
  //position: fixed
  //left: 0
  //top: 0
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: .2rem
  height: 100dvh
  width: 30dvw
  background: white
  box-shadow: $box-shadow
  h1
    font-size: 2rem
    text-decoration: underline

.nav-buttons
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  gap: .5rem
.active-link
  color: $dark-color
  text-decoration: underline
  font-weight: bold

.algorithms
  background: $light-color
  padding: 1rem
  text-align: center
  box-shadow: $box-shadow
  border-radius: 10px
  list-style-type: none

@media screen and (max-width: 1100px)
  .navigation
    width: 100dvw
    height: 30dvh