.home-page {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.main-app {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
  width: 100%;
  height: 100vh;
  margin: 0 0 0 2rem;
  max-width: 100dvw;
  overflow-x: auto;
}

@media screen and (max-width: 1100px) {
  .home-page {
    flex-direction: column;
  }

  .main-app {
    margin-top: 3rem;
    align-items: flex-start;
  }
}

