.input {
  background: white;
  border: 1px solid black;
  padding: 0.5rem;
}
.input:focus, .input:hover {
  border: 1px solid #0B3954;
}

.slider-input {
  text-align: center;
}

