@import '../../../utils/variables'

.modal
  background: whitesmoke
  box-shadow: $box-shadow
  position: fixed
  top: 10px
  right: 10px
  //transform: translateX(-50%)
  max-width: 30vw
  height: calc(100vh - 20px)
  overflow: auto
  z-index: 6
  border-radius: 10px
.modal-header
  h2
    margin: 0
    padding: 0
  background: $dark-color
  color: $light-color
  margin: 0
  padding: 1rem
  text-align: center

.modal-content
  padding: 1rem


@media screen and (max-width: 1100px)
  .modal
    max-width: 90vw
    width: 100%
    height: 20dvh
    left: 50%
    transform: translateX(-50%)
    bottom: 10px