.error-modal {
  position: static;
  height: 100%;
  width: 100%;
}

.error-modal-content {
  text-align: center;
}

.error-data {
  font-weight: bold;
  font-size: 1.3rem;
  color: #0B3954;
}

.error-generic {
  font-weight: 300;
}

.error-modal-header {
  display: flex;
  gap: 0.4rem;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

