html {
  scroll-behavior: smooth;
}

body {
  background: whitesmoke;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

input, button, option {
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

.wrapper {
  width: 80%;
  margin: 0 auto;
}

.close-svg:hover {
  animation-name: spin;
  animation-duration: 0.7s;
  transition: all 0.2s;
}

@keyframes spin {
  from {
    transform: rotate(0) scale(1.1);
  }
  to {
    transform: rotate(360deg) scale(1.1);
  }
}
.icon-svg {
  max-width: 40px;
  transition: all 0.2s;
}
.icon-svg:hover, .icon-svg:focus {
  transform: scale(1.1);
  transition: all 0.2s;
}

@media screen and (max-width: 1100px) {
  html {
    font-size: 13px;
  }
}

